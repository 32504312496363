.signature-input-container {
  @media (max-width: 700px) {
    min-height: 13em;
  }


  .canvas-top {
    display: flex;
    justify-content: space-between;

    .clear-canvas {

    }
  }

  .sign-box {
    @media (max-width: 700px) {
      height: 13em;
    }

    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;

    .sigCanvas {
      width: 100%;
      height: 100%;
    }
  }
}
