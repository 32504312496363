
.toast {
  background-color: rgba(#fff, .95);
  border-color: $border-color;
  box-shadow: 0 2px 3px rgba($gray-900, .06);
  @include border-radius();
}

.toast-header {
  border-bottom-color: rgba($gray-300, .4);

  .close {
    font-weight: $font-weight-light;
    color: $gray-500;
  }
}
