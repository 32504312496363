.list-group {
  .list-group-item:hover {
    background-color: $color-ui-02;
  }
}

.dashboard-content {
  min-height: calc(100vh - #{$height-header + $height-footer});
}

.document-card:hover {
  background-color: $color-ui-02;
}

.document-card {
  color: black;
}