table.table-hover tbody tr:hover {
  background-color: $color-ui-02;
}

.patients-content {
  min-height: calc(100vh - #{$height-header + $height-footer});
}

.plus-icon {
  vertical-align: middle;
}


@media (max-width: 700px) {
  .navbar-brand {
    display: none !important;
  }
}
