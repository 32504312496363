table.table-hover tbody tr:hover {
  background-color: $color-ui-02;
}

.doctors-content {
  min-height: calc(100vh - #{$height-header + $height-footer});
}

.plus-icon {
  vertical-align: middle;
}

.custom-table-row {
  cursor: pointer;
}
