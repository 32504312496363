@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 100;
  src: url("/assets/fonts/inter-ui/Inter-UI-Thin-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Thin-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 100;
  src: url("/assets/fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 200;
  src: url("/assets/fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 200;
  src: url("/assets/fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 300;
  src: url("/assets/fonts/inter-ui/Inter-UI-Light-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Light-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 300;
  src: url("/assets/fonts/inter-ui/Inter-UI-LightItalic-BETA.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: url("/assets/fonts/inter-ui/Inter-UI-Regular.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: url("/assets/fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  src: url("/assets/fonts/inter-ui/Inter-UI-Medium.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  src: url("/assets/fonts/inter-ui/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 600;
  src: url("/assets/fonts/inter-ui/Inter-UI-SemiBold.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 600;
  src: url("/assets/fonts/inter-ui/Inter-UI-SemiBoldItalic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  src: url("/assets/fonts/inter-ui/Inter-UI-Bold.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: url("/assets/fonts/inter-ui/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 800;
  src: url("/assets/fonts/inter-ui/Inter-UI-ExtraBold.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 800;
  src: url("/assets/fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 900;
  src: url("/assets/fonts/inter-ui/Inter-UI-Black.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 900;
  src: url("/assets/fonts/inter-ui/Inter-UI-BlackItalic.woff2") format("woff2"),
       url("/assets/fonts/inter-ui/Inter-UI-BlackItalic.woff") format("woff");
}

/* --------------------------------------------------------------------------
Single variable font.

Note that you may want to do something like this to make sure you're serving
constant fonts to older browsers:
html {
  font-family: 'Inter UI', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var', sans-serif;
  }
}

BUGS:
- Safari 12.0 will default to italic instead of regular when font-weight
  is provided in a @font-face declaration.
  Workaround: Use "Inter UI var alt" for Safari, or explicitly set
  `font-variation-settings:"slnt" DEGREE`.
*/
//@font-face {
//  font-family: 'Inter UI var';
//  font-weight: 100 900;
//  font-style: oblique 0deg 10deg;
//  src: url("Inter-UI.var.woff2") format("woff2-variations"),
//       url("Inter-UI.var.woff2") format("woff2");
//}

/* --------------------------------------------------------------------------

"Inter UI var alt" is recommended for Safari and Edge, for reliable italics.

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var alt', sans-serif;
  }
}

*/
//@font-face {
//  font-family: 'Inter UI var alt';
//  font-weight: 100 900;
//  font-style: normal;
//  font-named-instance: 'Regular';
//  src: url("/assets/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2 supports variations(gvar)"),
//       url("/assets/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2-variations"),
//       url("/assets/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2");
//}
//@font-face {
//  font-family: 'Inter UI var alt';
//  font-weight: 100 900;
//  font-style: italic;
//  font-named-instance: 'Italic';
//  src: url("/assets/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2 supports variations(gvar)"),
//       url("/assets/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2-variations"),
//       url("/assets/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2");
//}
