.wizard-list {
  li {
    padding-bottom: 1rem;
  }
}

.steps-vertical {
  top: 2em;
}

.wizard-btn-group {
  text-align: center;
  padding: 4em 0;
}
