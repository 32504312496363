// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $primary;
  color: white;
  border-color: $primary;
}

.CalendarDay__selected:hover {
  background: $primary;
  color: white;
}

.DayPickerKeyboardShortcuts_buttonReset::before {
  border-right-color: $primary;
}