.navbar-profile-dropdown {
  .profile-icon {
    color: $primary;
    font-size: 30px;

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-item:hover {
    cursor: pointer;
  }
}